import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      h1: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      },
      h2: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      },
      h3: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      },
      h4: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      },
      h5: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      },
      h6: {
        fontFamily: "'Ubuntu', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
      },
    },
  })
);

export default theme;
