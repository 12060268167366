import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Main } from "./components";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact>
          <Main />
        </Route>
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
