import { makeStyles } from "@material-ui/core";

const useRootStyles = () => {
  const classes = makeStyles((theme) => {
    return {
      root: {
        height: "100vh",
      },
    };
  });
  return classes();
};

export default useRootStyles;
