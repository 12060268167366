import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet-async";

/* COMPONENT */
const Head = ({ fonts, title }) => {
  /* RENDERERS */
  /**
   * Adds a list of fonts to the head of the HTML document
   * @param {[] || string} fonts Fonts to be added to the head of the HTML document
   * @returns HTML link tag for the fonts
   */
  const renderFontsTag = (fonts) => {
    /**
     * Formats the fonts family name to insert into link tag
     * @param {string} font Font family formated insert in link tag
     * @returns Font family formated to be inserted in link tag
     */
    const formatFontName = (font) => {
      return `family=${font.replace(/ /g, "+")}&`;
    };
    let tag;
    if ((Array.isArray(fonts) || typeof fonts === "string") && fonts.length) {
      fonts = Array.isArray(fonts)
        ? fonts.map((font) => formatFontName(font))
        : formatFontName(fonts);
      tag = (
        <link
          href={`https://fonts.googleapis.com/css2?${fonts}display=swap`}
          rel='stylesheet'
        />
      );
    }
    return tag;
  };

  /* JSX */
  return (
    <Helmet>
      <title>Damien Artero</title>
      <meta name='theme-color' content='#000000' />
      <meta name='description' content={title} />
      {renderFontsTag(fonts)}
    </Helmet>
  );
};

Head.propTypes = {
  fonts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  title: PropTypes.string,
};
Head.defaultProps = {
  Description: "Développeur d'applications web, fullstack React.js et Node.js",
};

export default Head;
