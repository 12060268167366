import { Grid, Typography } from "@material-ui/core";
import { useRootStyles } from "../../styles";

const Main = () => {
  const classes = useRootStyles();
  return (
    <Grid
      className={classes.root}
      container
      justify='center'
      alignItems='center'
    >
      <Typography variant='h1'>Coming Soon !</Typography>
    </Grid>
  );
};

export default Main;
