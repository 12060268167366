import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { HelmetProvider } from "react-helmet-async";
import { Head, Router } from "./components";
import { theme } from "./styles";

function App() {
  return (
    <HelmetProvider>
      <Head fonts={"Ubuntu"} />
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
